import kountSDK from '@kount/kount-web-client-sdk'

export default {
  name: 'KountScript',
  props: {
    sessionId: {
      type: String
    },
    clientId: {
      type: String
    },
    environment: {
      type: String
    }
  },
  created () {
    const config = {
      clientID: this.clientId,
      environment: this.environment,
      isSinglePageApp: false,
      isDebugEnabled:  true,
    }
    const sdk = kountSDK(config, this.sessionId)
    if (sdk) {
      console.log('Anti-fraud SDK activated!')
    }
  }
}
