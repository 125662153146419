import Vue from 'vue'
import EventBus from '@/plugins/event-bus.js'
import KountScript from '@/components/KountScript/index.vue'

Vue.use(EventBus)
Vue.component('kount-script', KountScript)

const config = {}
document.addEventListener('DOMContentLoaded', function () {
  new Vue(config).$mount('#risk-evaluation')
})
